import React from 'react'

import { Helmet } from 'react-helmet'
import Layout from '../../components/layout'
import SEO from '../../components/seo'

const AppPrivacyPolicy = () => (
  <Layout>
    <SEO title="Privacy Policy for Apps" />

    <Helmet>
      <meta charSet="utf-8" />
      <meta name="description" content="Learn how we can transform your expectations of custom software." />
      <title>Privacy Policy for Apps | LaMar Software</title>
      <link rel="canonical" href="https://lamarsoftware.io/privacy-policy/apps" />
    </Helmet>

    <section className="privacy-policy">
      <h2>Privacy Policy</h2>

      <p>
        <b>Introduction</b>
      </p>

      <p>
        We respect your privacy and are committed to protecting it through our compliance with this policy. This policy
        describes the types of information we may collect from you or that you may provide when you visit or use Pool
        Orchard or Service Planner (hereinafter the "Apps") and our practices for collecting, using, maintaining,
        protecting, and disclosing that information.
      </p>

      <p>This policy applies to information we collect:</p>

      <ul>
        <li>
          <p>Using our Apps.</p>
        </li>

        <li>
          <p>In email, text, and other electronic messages between you and our Apps.</p>
        </li>

        <li>
          <p>
            When you interact with our advertising and applications on third-party websites and services, if those
            applications or advertising include links to this policy. It does not apply to information collected by:
          </p>
        </li>

        <li>
          <p>Us offline or through any other means, including on any other application or Apps operated by us; or</p>
        </li>
        <li>
          <p>
            Any third party, including through any application or content (including advertising) that may link to or be
            accessible from or on our Apps.
          </p>
        </li>
      </ul>

      <p>
        Please read this policy carefully to understand our policies and practices regarding your information and how we
        will treat it. If you do not agree with our policies and practices, your choice is not to use our Apps. By
        accessing or using our Apps, you agree to this privacy policy. This policy may change from time to time (see
        <i>Changes to Our Privacy Policy</i>). Your continued use of our Apps after we make changes is deemed to be
        acceptance of those changes, so please check the policy periodically for updates.
      </p>

      <p>
        <b>Children Under the Age of 16</b>
      </p>

      <p>
        We do not knowingly collect personal information from children under 16. If you are under 16, do not use or
        provide any information on our Apps. If we learn we have collected or received personal information from a child
        under 16 without verification of parental consent, we will delete that information. If you believe we might have
        any information from or about a child under 16, please contact us.
      </p>

      <p>
        <b>Information We Collect About You and How We Collect It</b>
      </p>

      <p>We collect several types of information from and about users of our Apps, including information:</p>

      <ul>
        <li>
          <p>
            By which you may be personally identified, such as name, postal address, e-mail address, telephone number or
            any other identifier by which you may be contacted online or offline (”personal information”);
          </p>
        </li>

        <li>
          <p>That is about you but individually does not identify you; and/or</p>
        </li>

        <li>
          <p>
            About your internet connection, the equipment you use to access our Apps, and usage details. We collect this
            information:
          </p>
        </li>

        <li>
          <p>Directly from you when you provide it to us.</p>
        </li>

        <li>
          <p>
            Automatically as you navigate through the site. Information collected automatically may include usage
            details, IP addresses, and information collected through cookies, and other tracking technologies.
          </p>
        </li>

        <li>
          <p>From third parties, for example, our business partners.</p>
        </li>
      </ul>

      <p>
        <b>Information You Provide to Us</b>
      </p>

      <p>The information we collect on or through our Apps may include:</p>

      <ul>
        <li>
          <p>
            Information that you provide by filling in forms on our Apps. This includes information provided at the time
            of subscribing to our service, or requesting further services. We may also ask you for information when you
            enter a contest or promotion sponsored by us, and when you report a problem with our Apps.
          </p>
        </li>

        <li>
          <p>Records and copies of your correspondence (including email addresses), if you contact us.</p>
        </li>

        <li>
          <p>
            Your responses to surveys that we might ask you to complete for research purposes. Information We Collect
            Through Automatic Data Collection Technologies As you navigate through and interact with our Apps, we may
            use automatic data collection technologies to collect certain information about your equipment, browsing
            actions, and patterns, including: Details of your visits to our Apps, including traffic data, location data,
            logs, and other communication data and the resources that you access and use on our Apps; Information about
            your computer and internet connection, including your IP address, operating system, and browser type.
          </p>
          <p>
            The information we collect automatically is only statistical data and does not include personal information.
            It helps us to improve our Apps and to deliver a better and more personalized service, including by enabling
            us to: Estimate our audience size and usage patterns; Store information about your preferences, allowing us
            to customize our Apps according to your individual interests; Recognize you when you return to our Apps. The
            technologies we use for this automatic data collection may include: <b>Cookies (or browser cookies)</b>. A
            cookie is a small file placed on the hard drive of your computer. You may refuse to accept browser cookies
            by activating the appropriate setting on your browser. However, if you select this setting you may be unable
            to access certain parts of our Apps. Unless you have adjusted your browser setting so that it will refuse
            cookies, our system will issue cookies when you direct your browser to our Apps.
          </p>
        </li>
      </ul>

      <p>
        <b>How We Use Your Information</b>
      </p>

      <p>We use information that we collect about you or that you provide to us, including any personal information:</p>

      <ul>
        <li>
          <p>To present our Apps and its contents to you.</p>
        </li>

        <li>
          <p>To provide you with information, products, or services that you request from us.</p>
        </li>

        <li>
          <p>To fulfill any other purpose for which you provide it.</p>
        </li>

        <li>
          <p>To notify you about changes to our Apps or any products or services we offer or provide though it.</p>
        </li>
      </ul>

      <p>
        <b>Disclosure of Your Information</b>
      </p>

      <p>We may disclose personal information that we collect or you provide as described in this privacy policy:</p>

      <ul>
        <li>
          <p>
            To fulfill the purpose for which you provide it. For example, if you give us an email address to use the
            “email a friend” feature of our Apps, we will transmit the contents of that email and your email address to
            the recipients.
          </p>
        </li>

        <li>
          <p>
            We may also disclose your personal information to comply with any court order, law, or legal process,
            including to respond to any government or regulatory request.
          </p>
        </li>
      </ul>

      <p>
        <b>Choices About How We Use and Disclose Your Information</b>
      </p>

      <p>
        We strive to provide you with choices regarding the personal information you provide to us. We have created
        mechanisms to provide you with the following control over your information:
      </p>

      <ul>
        <li>
          <p>
            <b>Tracking Technologies and Advertising.</b> You can set your browser to refuse all or some browser
            cookies, or to alert you when cookies are being sent.
          </p>
        </li>
      </ul>

      <p>
        We do not control third parties’ collection or use of your information to serve interest-based advertising.
        However these third parties may provide you with ways to choose not to have your information collected or used
        in this way. You can opt out of receiving targeted ads from members of the Network Advertising Initiative
        (”NAI”) on the NAI’s <a href="http://optout.networkadvertising.org/?c=1">Apps</a>. California residents may have
        additional personal information rights and choices. Please see [<i>Your California Privacy Rights</i>] for more
        information.
      </p>

      <p>
        <b>Your California Privacy Rights</b>
      </p>

      <p>
        If you are a California resident, California law may provide you with additional rights regarding our use of
        your personal information. To learn more about your California privacy rights, visit
        <a href="https://oag.ca.gov/privacy/privacy-laws">https://oag.ca.gov/privacy/privacy-laws</a>.
      </p>

      <p>
        <b>Notice to European Users</b>
      </p>

      <p>
        This Site and the services on this Site are targeted for users in the United States of America. Any information
        you enter on this Site may be transferred outside of the European Union to the United States of America which
        does not offer an equivalent level of protection to that required in the European Union. In particular, you are
        advised that the United States of America uses a sectoral model of privacy protection that relies on a mix of
        legislation, governmental regulation, and self-regulation. Article 26 of the European Union’s Data Protection
        Directive (Directive 95/46/EC, 1995 O.J. (L 281) 31) allows for transfer of personal data from the European
        Union to a third country if the individual has unambiguously given his consent to the transfer of personal
        information, regardless of the third country’s level of protection. By using this Site or the services, you
        consent to the transfer of all such information to the United States of America which may not offer an
        equivalent level of protection to that required in the European Union and to the processing of that information
        by the Company on its servers located in the United States of America as described in this Privacy Policy.
      </p>

      <p>
        <b>Changes to Our Privacy Policy</b>
      </p>

      <p>
        It is our policy to post any changes we make to our privacy policy on this page with a notice that the privacy
        policy has been updated on our Apps home page. If we make material changes to how we treat our users’ personal
        information, we will notify you through a notice on our Apps home page. The date the privacy policy was last
        revised is identified at the top of the page. You are responsible for ensuring we have an up-to-date active and
        deliverable email address for you, and for periodically visiting our Apps and this privacy policy to check for
        any changes.
      </p>

      <p>Contact Information</p>

      <p>
        To ask questions or comment about this privacy policy and our privacy practices, contact us at:
        <a href="mailto:marketing@nva.com">marketing@nva.com</a>.
      </p>

      <h2>Privacy Notice for California Residents</h2>

      <p>
        <b>Effective Date</b>: March 2019
      </p>

      <p>
        <b>Last Reviewed on</b>: March 2019
      </p>

      <p>
        This <b>Privacy Notice for California Residents</b> supplements the information contained in our privacy policy
        above and applies solely to all visitors, users, and others who reside in the State of California (”consumers”
        or “you”). We adopt this notice to comply with the California Consumer Privacy Act of 2018 (CCPA) and any terms
        defined in the CCPA have the same meaning when used in this notice.
      </p>

      <p>
        <b>Information We Collect</b>
      </p>

      <p>
        Our Apps collects information that identifies, relates to, describes, references, is capable of being associated
        with, or could reasonably be linked, directly or indirectly, with a particular consumer or device (”
        <b>personal information</b>”). In particular, our Apps has collected the following categories of personal
        information from its consumers within the last twelve (12) months:
      </p>

      <p>
        <b>Category A:</b> Identifiers
      </p>
      <p>
        <b>Examples:</b> A real name, Internet Protocol address, email address, or other similar identifiers.
      </p>
      <p>
        <b>Collected:</b> YES
      </p>

      <p>
        <b>Category B:</b> Personal information categories listed in the California Customer Records statute (Cal. Civ.
        Code § 1798.80(e)).
      </p>
      <p>
        <b>Examples:</b> A name, signature, Social Security number, physical characteristics or description, address,
        telephone number, passport number, driver’s license or state identification card number, insurance policy
        number, education, employment, employment history, bank account number, credit card number, debit card number,
        or any other financial information, medical information, or health insurance information. Some personal
        information included in this category may overlap with other categories.
      </p>
      <p>
        <b>Collected:</b> NO
      </p>

      <p>
        <b>Category C:</b> Protected classification characteristics under California or federal law.
      </p>
      <p>
        <b>Examples:</b> Age (40 years or older), race, color, ancestry, national origin, citizenship, religion or
        creed, marital status, medical condition, physical or mental disability, sex (including gender, gender identity,
        gender expression, pregnancy or childbirth and related medical conditions), sexual orientation, veteran or
        military status, genetic information (including familial genetic information).
      </p>
      <p>
        <b>Collected:</b> NO
      </p>

      <p>Personal information does not include:</p>
      <ul>
        <li>
          <p>Publicly available information from government records.</p>
        </li>

        <li>
          <p>De-identified or aggregated consumer information.</p>
        </li>

        <li>
          <p>Information excluded from the CCPA’s scope, like:</p>
        </li>
      </ul>

      <ul>
        <li>
          <p>
            health or medical information covered by the Health Insurance Portability and Accountability Act of 1996
            (HIPAA) and the California Confidentiality of Medical Information Act (CMIA) or clinical trial data;
          </p>
        </li>

        <li>
          <p>
            personal information covered by certain sector-specific privacy laws, including the Fair Credit Reporting
            Act (FRCA), the Gramm-Leach-Bliley Act (GLBA) or California Financial Information Privacy Act (FIPA), and
            the Driver’s Privacy Protection Act of 1994.
          </p>
        </li>
      </ul>

      <p>We obtains the categories of personal information listed above from the following categories of sources:</p>

      <ul>
        <li>
          <p>Directly from you. For example, from forms you complete on our Apps.</p>
        </li>

        <li>
          <p>
            Indirectly from you. For example, from observing your actions on our Apps or interactions with our
            advertisers.
          </p>
        </li>
      </ul>

      <p>
        <b>Use of Personal Information</b>
      </p>

      <p>
        We may use, or disclose the personal information we collect for one or more of the following business purposes:
      </p>

      <ul>
        <li>
          <p>
            To fulfill or meet the reason you provided the information. For example, if you share your name and contact
            information to request a price quote or ask a question about our products or services, we will use that
            personal information to respond to your inquiry. If you provide your personal information to purchase a
            product or service, we will use that information to process your payment and facilitate delivery. We may
            also save your information to facilitate new product orders or process returns.
          </p>
        </li>

        <li>
          <p>
            To provide you with support and to respond to your inquiries, including to investigate and address your
            concerns and monitor and improve our responses.
          </p>
        </li>

        <li>
          <p>
            To respond to law enforcement requests and as required by applicable law, court order, or governmental
            regulations.
          </p>
        </li>

        <li>
          <p>As described to you when collecting your personal information or as otherwise set forth in the CCPA.</p>
        </li>

        <li>
          <p>
            We will not collect additional categories of personal information or use the personal information we
            collected for materially different, unrelated, or incompatible purposes without providing you notice.
          </p>
        </li>
      </ul>

      <p>
        <b>Sharing Personal Information</b>
      </p>

      <p>
        We may disclose your personal information to a third party for a business purpose [or sell your personal
        information, subject to your right to opt-out of those sales (see
        <i>Personal Information Sales Opt-Out and Opt-In Rights</i>)]. When we disclose personal information for a
        business purpose, we enter a contract that describes the purpose and requires the recipient to both keep that
        personal information confidential and not use it for any purpose except performing the contract. [The CCPA
        prohibits third parties who purchase the personal information we hold from reselling it unless you have received
        explicit notice and an opportunity to opt-out of further sales.]
      </p>

      <p>
        We share your personal information with the following categories of third parties: Vetstoria, our online booking
        tool. Please see
        <a href="https://www.vetstoria.com/privacy-policy/">https://www.vetstoria.com/privacy-policy/ </a>for their
        privacy policy.
      </p>

      <p>
        <b>Disclosures of Personal Information for a Business Purpose</b>
      </p>
      <p>In the preceding twelve (12) months, Company has not disclosed personal information for a business purpose.</p>

      <p>
        <b>Sales of Personal Information</b>
      </p>
      <p>In the preceding twelve (12) months, Company had not sold personal information.</p>

      <p>
        <b>Your Rights and Choices</b>
      </p>
      <p>
        The CCPA provides consumers (California residents) with specific rights regarding their personal information.
        This section describes your CCPA rights and explains how to exercise those rights.
      </p>

      <p>
        <b>Access to Specific Information and Data Portability Rights</b>
      </p>
      <p>
        You have the right to request that we disclose certain information to you about our collection and use of your
        personal information over the past 12 months. Once we receive and confirm your verifiable consumer request, we
        will disclose to you:
      </p>

      <ul>
        <li>
          <p>The categories of personal information we collected about you.</p>
        </li>

        <li>
          <p>The categories of sources for the personal information we collected about you.</p>
        </li>

        <li>
          <p>Our business or commercial purpose for collecting or selling that personal information.</p>
        </li>

        <li>
          <p>The categories of third parties with whom we share that personal information.</p>
        </li>

        <li>
          <p>
            The specific pieces of personal information we collected about you (also called a data portability request).
          </p>
        </li>
        <li>
          <p>
            If we sold or disclosed your personal information for a business purpose, two separate lists disclosing:
          </p>
        </li>

        <li>
          <p>sales, identifying the personal information categories that each category of recipient purchased; and</p>
        </li>

        <li>
          <p>
            disclosures for a business purpose, identifying the personal information categories that each category of
            recipient obtained.
          </p>
        </li>
      </ul>

      <p>
        <b>Deletion Request Rights</b>
      </p>

      <p>
        You have the right to request that we delete any of your personal information that we collected from you and
        retained, subject to certain exceptions. Once we receive and confirm your verifiable consumer request, we will
        delete (and direct our service providers to delete) your personal information from our records, unless an
        exception applies.
      </p>

      <p>
        We may deny your deletion request if retaining the information is necessary for us or our service provider(s)to:
      </p>

      <ul>
        <li>
          <p>
            Complete the transaction for which we collected the personal information, provide a good or service that you
            requested, take actions reasonably anticipated within the context of our ongoing business relationship with
            you, or otherwise perform our contract with you.
          </p>
        </li>

        <li>
          <p>
            Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity, or
            prosecute those responsible for such activities.
          </p>
        </li>

        <li>
          <p>Debug products to identify and repair errors that impair existing intended functionality.</p>
        </li>

        <li>
          <p>
            Exercise free speech, ensure the right of another consumer to exercise their free speech rights, or exercise
            another right provided for by law.
          </p>
        </li>

        <li>
          <p>Comply with the California Electronic Communications Privacy Act (Cal. Penal Code § 1546 et. seq.).</p>
        </li>

        <li>
          <p>
            Engage in public or peer-reviewed scientific, historical, or statistical research in the public interest
            that adheres to all other applicable ethics and privacy laws, when the information’s deletion may likely
            render impossible or seriously impair the research’s achievement, if you previously provided informed
            consent.
          </p>
        </li>

        <li>
          <p>
            Enable solely internal uses that are reasonably aligned with consumer expectations based on your
            relationship with us.
          </p>
        </li>

        <li>
          <p>Comply with a legal obligation.</p>
        </li>

        <li>
          <p>
            Make other internal and lawful uses of that information that are compatible with the context in which you
            provided it.
          </p>
        </li>
      </ul>

      <p>
        <b>Exercising Access, Data Portability, and Deletion Rights</b>
      </p>

      <p>
        To exercise the access, data portability, and deletion rights described above, please submit a verifiable
        consumer request to us by sending us a message on our Apps. Only you, or a person registered with the California
        Secretary of State that you authorize to act on your behalf, may make a verifiable consumer request related to
        your personal information. You may also make a verifiable consumer request on behalf of your minor child.
      </p>

      <p>
        You may only make a verifiable consumer request for access or data portability twice within a 12-month period.
        The verifiable consumer request must:
      </p>

      <ul>
        <li>
          <p>
            Provide sufficient information that allows us to reasonably verify you are the person about whom we
            collected personal information or an authorized representative.
          </p>
        </li>

        <li>
          <p>
            Describe your request with sufficient detail that allows us to properly understand, evaluate, and respond to
            it.
          </p>
        </li>
      </ul>

      <p>
        We cannot respond to your request or provide you with personal information if we cannot verify your identity or
        authority to make the request and confirm the personal information relates to you. We will only use personal
        information provided in a verifiable consumer request to verify the requester's identity or authority to make
        the request.
      </p>
      <p>
        <b>Response Timing and Format</b>
      </p>
      <p>
        We endeavor to respond to a verifiable consumer request within forty-five (45) days of its receipt. If we
        require more time (up to [45/90] days), we will inform you of the reason and extension period in writing. We
        will deliver our written response by mail or electronically, at your option. Any disclosures we provide will
        only cover the 12-month period preceding the verifiable consumer request’s receipt. The response we provide will
        also explain the reasons we cannot comply with a request, if applicable. For data portability requests, we will
        select a format to provide your personal information that is readily useable and should allow you to transmit
        the information from one entity to another entity without hindrance, specifically by electronic mail
        communication.
      </p>
      <p>
        We do not charge a fee to process or respond to your verifiable consumer request unless it is excessive,
        repetitive, or manifestly unfounded. If we determine that the request warrants a fee, we will tell you why we
        made that decision and provide you with a cost estimate before completing your request.
      </p>

      <p>
        <b>Personal Information Sales Opt-Out and Opt-In Rights</b>
      </p>
      <p>
        If you are 16 years of age or older, you have the right to direct us to not sell your personal information at
        any time (the “right to opt-out”). We do not sell the personal information of consumers we actually know are
        less than 16 years of age, unless we receive affirmative authorization (the “right to opt-in”) from either the
        consumer who is between 13 and 16 years of age, or the parent or guardian of a consumer less than 13 years of
        age. Consumers who opt-in to personal information sales may opt-out of future sales at any time. To exercise the
        right to opt-out, you (or your authorized representative) may submit a request to us by visiting the following
        our webpage and sending us a message.
      </p>
      <p>
        Once you make an opt-out request, we will wait at least twelve (12) months before asking you to reauthorize
        personal information sales. However, you may change your mind and opt back in to personal information sales at
        any time by visiting our Apps and sending us a message. We will only use personal information provided in an
        opt-out request to review and comply with the request.
      </p>
      <p>
        <b>Non-Discrimination</b>
      </p>
      <p>
        We will not discriminate against you for exercising any of your CCPA rights. Unless permitted by the CCPA, we
        will not:
      </p>

      <ul>
        <li>
          <p>Deny you goods or services.</p>
        </li>

        <li>
          <p>
            Charge you different prices or rates for goods or services, including through granting discounts or other
            benefits, or imposing penalties.
          </p>
        </li>

        <li>
          <p>Provide you a different level or quality of goods or services.</p>
        </li>

        <li>
          <p>
            Suggest that you may receive a different price or rate for goods or services or a different level or quality
            of goods or services.
          </p>
        </li>
      </ul>

      <p>
        However, we may offer you certain financial incentives permitted by the CCPA that can result in different
        prices, rates, or quality levels. Any CCPA-permitted financial incentive we offer will reasonably relate to your
        personal information’s value and contain written terms that describe the program’s material aspects.
        Participation in a financial incentive program requires your prior opt in consent, which you may revoke at any
        time.
      </p>

      <p>
        California’s “Shine the Light” law (Civil Code Section § 1798.83) permits users of our Apps that are California
        residents to request certain information regarding our disclosure of personal information to third parties for
        their direct marketing purposes. To make such a request, please send us an electronic message through our Apps
        or write us at our address listed on our webpage.
      </p>

      <p>
        <b>Changes to Our Privacy Notice</b>
      </p>

      <p>
        We reserve the right to amend this privacy notice at our discretion and at any time. When we make changes to
        this privacy notice, we will post the updated notice on our Apps and update the notice’s effective date. Your
        continued use of our Apps following the posting of changes constitutes your acceptance of such changes.
      </p>

      <p>
        <b>Contact Information</b>
      </p>

      <p>
        If you have any questions or comments about this notice, the ways in which we collect and uses your information
        described below [and in the Privacy Policy], your choices and rights regarding such use, or wish to exercise
        your rights under California law, please do not hesitate to contact us as follows
      </p>

      <ul>
        <li>
          <p>Via our Apps: please send us an electronic message through our Apps </p>
        </li>

        <li>
          <p>Write us at our address listed on our webpage.</p>
        </li>
      </ul>
    </section>
  </Layout>
)

export default AppPrivacyPolicy
